@import '~antd/dist/antd.dark.css';
.login-page-row {
    height:100vh;
    margin:unset !important;
}
.login-form-main{
    /* margin:auto !important;
    margin-left:unset !important; */
    width:85%;
    box-shadow: unset !important;
    padding:1%;
}
.info::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00467F;
    background: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
    opacity: 0.6;
}
.info{
    width: auto;
    height: 100vh;
    background-image: url('https://dev.boiler.livnsense.com/boiler_image.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}