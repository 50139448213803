@import '~antd/dist/antd.dark.less'; 
/* .App {
  text-align: center;
  background-color: #030f1a;
  background-size: cover;
  height:100vh !important;
} */

.logo {
  height: 40vmin;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

  pointer-events: none;
}
.ant-tag{
  font-size:14px;
  font-weight:bold;
  box-shadow: inset 0 0 6px black;
  border-radius:5px;
}

.lnslogo{
  /* width:30px; */
  border-radius: 100px;
background-color: #fff;
}
.lnsgopslogo{
width:42px;


}



.App-header {
  background-color: #001529;
  height: 10vh;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  width: 100%;
}
.App-content {
  background-color: #001529;
  color: white;
  height: 90vh;
  text-align: center;
  width: 100%;
  display: flex;
align-items: center;
justify-content: center;
}
.App-footer {
  background-color: #1f2533;
  color: white;
  text-align: center;
  height: 10vh;
  width: 100%;
  
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background-color: #171717;
  margin:10px !important;
}
.logo{
  height:10vh;
}
.ant-layout-sider-children{
  background-color: #171717;
  height:100vh
}

.trigger{
  color: #f3f3f3;
}
.ant-layout,.site-layout{
  background-color: #1e1e1e;

}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:5px;
  padding:5px;
  
}
.center1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  padding:5px;
  margin-top: 0px;
    margin-bottom: 0px;
  
}

.ant-upload,.ant-upload-btn{
 background-color: #353535 !important;
}

.ant-upload.ant-upload-drag{
  border: unset;
}

.card1{
  width: 99%;
  color:#f3f3f3;
  background-color: #171717;
  min-height:205px;
  height:auto;
  text-align: left;
  border-color: 'none';
  border-radius: 10px;
  border-width: 0px;
  
}
.card2{
  width: 99%;
  color:#f3f3f3;
  background-color: #1e1e1e;
  margin-left: 10px;
  min-height:205px;
  height:auto;
  text-align: left;
  border-color: 'none';
  border-radius: 10px;
  border-width: 0px;
  
}
.ant-card-head-title{
color:#f3f3f3;
}

.ant-card-head {
  border-bottom: 1px solid #494949;
}
.ant-row{
  margin-left: 5px;
    margin-right: -5px;
    row-gap: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  
    /* display:inherit !important; */
  
}

.ant-col,.ant-col-6{
  padding-left:20px; 
}
.ant-card,.ant-card-bordered,.ant-card-small{
margin-left: 10px !important;
margin-bottom: 10px;
}
.ant-layout-content{
margin: 5px !important;
}

.card{
  background-color:#141414;
  border-width:0px;
  min-height:205px;
  border-radius:10px;
  height:auto;
}

.card1{
  background-color:#171717;
  border-width:0px;
  min-height:205px;
  border-radius:10px;
  height: auto;
}
.card-default{
  /* background-color:#171717; */
  border-width:0px;
  height:100%;
  width:100%;
  border-radius:10px;
}
.card-default-dt{
  /* background-color:#171717; */
  border-width:0px;
  height:100%;
  width:100%;
  border-radius:10px;
  margin-left:unset !important;
}
.ant-menu-item {
/* margin-left: 6px; */
/* padding-left: 24px; */
background-color: #303030;
border-radius: 8px;
/* border: 1px solid; */
width: 50%;
}

.ant-menu-inline{
  border-right: unset;
}
.ant-menu.ant-menu-dark{
background-color: #171717;
}
.ant-input-group-addon button{
    border-radius:unset !important;
}
.ant-menu-vertical,.ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{

 

  border-right: 3px solid #3a3a3a;

}
.form-main-container{
  margin:10px;
  padding:10px;
  border-radius:10px;
  min-height:415px;
}
.formlabel{
  font-size:0.8rem;
  display:block;
  /* font-weight:bold; */
}

.inputbox{
  outline:unset;
  border:1px solid #17c1e8;
  border-radius:4px;
  padding:10px;
  margin:10px;
  min-width:184px;
  color: #141414;

}
.dashboard-row{
  margin-right:unset !important;
  margin-left:-10px !important;
  height:100%;
}
.dashboard-row-eda{
  margin-right:unset !important;
  margin-left:-10px !important;
  height:100%;
}
.dashboard-row-eda .ant-card-body{
  padding:unset !important;
  height:100%;
}
.eda-main-row{
  margin:unset !important;
  height:100%;
}
.eda-content-col-chart{
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  box-shadow:rgb(0 0 0) 0px 0px 10px inset;
}
.ant-row{
  margin-top:unset !important;
  margin-bottom:unset !important;
}
.ant-radio-wrapper{
  font-size:16px;
}
/* .dashboard-col{
  height:inherit;
  padding-right:4px;
  padding-left:4px;

} */
.ant-menu-item{
  width:unset !important;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {

  background-color: #1f1f1f;
  box-shadow: rgb(0 0 0) 0px 0px 2px inset;
  border:1px solid #303030;

}

.ant-menu-item-selected {

  color: rgb(255 255 255 / 85%);

}
.table-pan{
  max-height: 470x;
  overflow:auto;
}
.table-height {
  max-height:540px;
  overflow:auto;
}
.table-height-eda {
  height:inherit;
  overflow:auto;
}
p, .ant-tabs-tab-btn, .ant-menu-title-content, .ant-btn span {
  font-size: 15px;
}
.ant-btn {
  padding: 0px 10px !important;
}

.js-plotly-plot .plotly .modebar {
  position: absolute;
  top: 2px;
  right: 29px;
}
@media only screen and (min-width: 1800px) {
  .table-height{
    max-height:100%;
  }
  .table-pan{
    max-height:100%;
  }
}


@media only screen and (min-width: 768px) {
  .uploadwidth{
    width: 400px;
  padding-top: 2%;
  padding-bottom: 2%;
}
/* .exportbutton{
  margin-left: "unset";
  margin-right: "unset";
    display: "flex" !important ;
}
.ant-row{
  display:inherit !important
} */
.dashboard-col{
  width:100%;
  padding-right:4px;
  padding-left:4px;

}

.login-form-main{
width: 100%;
}

.ant-col, .ant-col-6 {
  padding-left: 0px;
}
.ant-col-9 {

  max-width:100%;

  /* flex:unset; */

}

.ant-col-15 {

  max-width: 100%;

  /* flex: unset; */

}
.ant-col-10{

  max-width:100%;

  /* flex:unset; */

}

.ant-col-14 {

  max-width: 100%;

  /* flex: unset; */

}
}
@media only screen and (min-width: 992px) {
  .uploadwidth{
    width: 500px;
  padding-top: 2%;
  padding-bottom: 2%;
}
/* .ant-row{
  display:inherit !important
} */
.dashboard-col{
  width:100%;
  padding-right:4px;
  padding-left:4px;

}
.ant-col, .ant-col-6 {
  padding-left: 20px;
}
.ant-col-9 {

  max-width:100%;

  /* flex:unset; */

}

.ant-col-15 {

  max-width: 100%;

  /* flex: unset; */

}
}
@media only screen and (min-width: 1200px) {
  .uploadwidth{
    width: 500px;
  padding-top: 2%;
  padding-bottom: 2%;
}
.ant-col, .ant-col-6 {
  padding-left: 20px;
}
.dashboard-col{
  height:100%;
  width: 100%;
  padding-right:4px;
  padding-left:4px;

}

 /* .ant-row{
  display: flex !important
} */
.ant-col-9 {

  max-width:35%

}

.ant-col-15 {

  max-width: 65%;

}
.ant-col-10 {

  max-width:37%;
  width: 36%;

}

.ant-col-14 {

  max-width: 63%;
  width:64%

}
.table-height{
  max-height:100%;
}
.table-pan{
  max-height:100%;
}
}
/* @media only screen and (min-width: 1326px) {
  .uploadwidth{
    width: 500px;
  padding-top: 2%;
  padding-bottom: 2%;
}
.ant-col, .ant-col-6 {
  padding-left: 20px;
}
.dashboard-col{
  height:100%;
  width: 100%;
  padding-right:4px;
  padding-left:4px;

}
.ant-col-9 {

  max-width:35%

}

.ant-col-15 {

  max-width: 65%;

}
.ant-col-10 {

  max-width:37%

}

.ant-col-14 {

  max-width: 63%;

}
.table-height{
  max-height:100%;
}
.table-pan{
  max-height:100%;
}
} */

@media only screen and (min-width: 1600px) {
  .uploadwidth{
    width: 500px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.ant-col, .ant-col-6 {
  padding-left: 20px;
}
.dashboard-col{
  height:inherit;
  padding-right:4px;
  padding-left:4px;

}
.ant-col-9 {

  max-width:33.3333%

}

.ant-col-15 {

  max-width: 66.6667%;

}
}
.model-placeholder {
  width: auto;
  height: 100%;
  min-height:512px;
  background-image: url('./assets/bgoutputimage.png');
  overflow:auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  display:grid;
}

.image-placeholder{
  /* padding-top: 10px; */
   width: auto;
  height: 100%;
  min-height:512px;
  background-image: url('./assets/diskette.png');
  overflow:auto;
  background-repeat: no-repeat;
  background-size: 70%;
  position: relative;
  z-index: 1;
  display:grid;
  /* justify-content: center; */
}



.model-placeholder::before{
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgb(23, 23, 23), rgb(23, 23, 23));
  opacity: 0.95;
}
.ant-tabs-content{
  height:100%;
}

