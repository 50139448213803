@import '~antd/dist/antd.dark.less'; 

body {
  margin: 0;
  font-family: 'Lato' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100vh;
  overflow-y:hidden;
}
input{
  height:unset !important;
}
td.ant-table-cell{
  padding:14px 14px !important;
}
.ant-row{
  margin-right:12px !important;
}
.ant-checkbox + span{
  font-size:16px;
}
::-webkit-scrollbar-thumb {
  background: rgba(73, 73, 73, 1);
  box-shadow: inset 0 0 6px black;
}
.ant-message-notice-content{
  width:200px;
  
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
  padding-top: 2%;
  padding-bottom: 2%;
  height: 5px;
}
p,h1,h2,h3,h4,h5,label{
  color:rgba(255, 255, 255, 0.85) !important;
}
p,.ant-tabs-tab-btn,.ant-menu-title-content,.ant-btn span{
  font-size:16px;
}
.ant-tabs-tab{
  padding:8px 8px !important;
}
span.ant-tag.ant-tag-has-color{
  font-size: 14px !important;

  color: white;
  padding:2.1%;
}
.ant-btn{
  padding:0 15px !important;
}
.ant-table{
  font-size:15px !important;
}
h1{
  font-size:22px;
}
h2{
  font-size:20px;
}
.card{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.ant-layout-header{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.ant-layout-footer{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.ant-upload{
  padding:4px 0 !important;
  border-radius:10px !important;
}
button,.ant-select-selector{
  border-radius:10px !important;
}
.ant-card-head-title{
  font-size:18px;
}
.ant-layout-content{
  overflow:auto;
  overflow-x:hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
